<script lang="ts" setup>
const title = ref('Ralph Controls')
const description = ref('My App Description')

useHead({
  title,
  meta: [
    {
      name: title,
      content: description,
    },
  ],
});

</script>

<template class="h-full">
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-3xl">

      <!-- Logo here -->
      <div class="flex justify-center">
        <img class="h-14 my-5 w-auto" src="/img/ralph_logo.svg" alt="Your Company" />
      </div>

      <!-- Main content -->
      <slot />

    </div>
  </div>
</template>


  